const dataTokenomics = [
  {
    id: 1,
    title: "Token Backing",
    desc: "Money Tree Token is backed by silver. This means that the value of the token is tied to the value of silver, providing stability and mitigating extreme token depreciations.",
    class: "",
  },
  {
    id: 2,
    title: "Token Supply",
    desc: "The number of Money Tree Tokens in supply is directly linked to the amount of silver ounces sold. Only 4,545 ounces of silver will be sold, and the token supply will depend on the number of silver ounces sold during the presale.",
    class: "right",
  },

  {
    id: 3,
    title: "Mining Mechanism",
    desc: "Tokens will not be pre-mined. Instead, they will be mined with each transaction. Every successful transaction results in the mining of tokens as a percentage of the transaction value. Only investors with membership subscriptions can mine coins through transactions.",
    class: "",
  },

  {
    id: 4,
    title: "Transaction Fee",
    desc: "For every transaction within the Money Tree Token ecosystem, a 10 percent transaction fee will be charged. This fee will be used to fund the day-to-day operations of the Money Tree Token ecosystem. 75 percent of the fee will be distributed to the community, and the remaining 25 percent will be used for ecosystem operations.",
    class: "right",
  },
  {
    id: 5,
    title: "Membership Fee",
    desc: "Investors are required to pay $50 for an annual membership. This fee provides various benefits, including the ability to mint Money Tree Token, community voting rights, participation in governance, membership to the jewelry store marketplace, access to platform statistics, and the ability to buy silver at a spot price or slightly above.",
    class: "",
  },
  {
    id: 6,
    title: "Staking and Liquidity Pools",
    desc: "Money Tree Token offers multiple earning avenues, including staking and participating in liquidity pools. 75 percent of the proceeds collected from transaction fees will be distributed to participants.",
    class: "",
  },
  {
    id: 7,
    title: "NFTs Marketplace",
    desc: "In the NFTs marketplace, 75 percent of the proceeds will go to NFTs holders, 10 percent to the treasury, another 10 percent to partners, and 5 percent to charity initiatives supporting startups.",
    class: "",
  },
  {
    id: 8,
    title: "Future Plans",
    desc: "The roadmap includes plans to change Money Tree Token into a coin and develop a private blockchain. These changes will be implemented once Money Tree Token has enough users, with the private blockchain aiming to provide a fast, secure, and affordable trading environment.",
    class: "",
  },
];

export default dataTokenomics;
