import React, { useState, useEffect } from "react";

import { Link, NavLink } from "react-router-dom";

import "./styles.scss";
import { ConnectKitButton } from "connectkit";
import { useMediaQuery, useTheme } from "@mui/material";
const Header = () => {
  const md = useMediaQuery("(max-width:1024px)");
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
    return () => {
      setScroll({});
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };
  let user = localStorage.getItem("connected");
  return (
    <header id="header_main" className={`header ${scroll ? "is-fixed" : ""}`}>
      <div className="container big">
        <div className="row">
          <div className="col-12">
            <div className="header__body">
              <div className="header__logo">
                <Link to="/">
                  <img
                    id="site-logo"
                    src="/logo.png"
                    alt=""
                    style={{
                      width: 65,
                      height: 65,
                      borderRadius: "50%",
                    }}
                  />
                </Link>
              </div>

              <div className="header__right">
                <nav
                  id="main-nav"
                  className={`main-nav ${menuActive ? "active" : ""}`}
                  style={{
                    paddingTop: md && "20px",
                    display: "flex",
                    flexDirection: md && "column",
                    alignItems: "center",
                    gap: md ? "25px" : "25px",
                  }}
                >
                  {md && (
                    <Link to="/">
                      <img
                        id="site-logo"
                        src="/logo.png"
                        alt=""
                        style={{
                          width: 65,
                          height: 65,
                          borderRadius: "50%",
                        }}
                      />
                    </Link>
                  )}
                  <Link
                    to="/"
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    className="menu-item"
                  >
                    HOME
                  </Link>
                  <a
                    href="/#about"
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    className="menu-item"
                  >
                    ABOUT
                  </a>
                  <a
                    href="/#nft"
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    className="menu-item"
                  >
                    NFT
                  </a>
                  <a
                    href="/#roadmap"
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    className="menu-item"
                  >
                    ROADMAP
                  </a>
                  <a
                    href="/#tokenomics"
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    className="menu-item"
                  >
                    TOKENOMICS
                  </a>
                  <a
                    href="/#partnership"
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    className="menu-item"
                  >
                    PARTNERSHIP
                  </a>
                  <Link
                    to="/presale"
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    className="menu-item"
                  >
                    PRESALE
                  </Link>
                  <Link
                    to="/stake"
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    className="menu-item"
                  >
                    STAKE
                  </Link>
                  <Link
                    to="/airdrop"
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    className="menu-item"
                  >
                    AIRDROP
                  </Link>
                  <ConnectKitButton.Custom>
                    {({ isConnected, truncatedAddress, show, ensName }) => {
                      return (
                        <button className="action-btn" onClick={show}>
                          <span>
                            {" "}
                            {isConnected
                              ? ensName ?? truncatedAddress
                              : "Connect Wallet"}
                          </span>
                        </button>
                      );
                    }}
                  </ConnectKitButton.Custom>
                </nav>
                {md &&
                  (!menuActive ? (
                    <img
                      src="/menu.svg"
                      alt=""
                      style={{
                        height: 45,
                      }}
                      onClick={() => setMenuActive(!menuActive)}
                    />
                  ) : (
                    <img
                      src="/close.svg"
                      alt=""
                      style={{
                        height: 52,
                      }}
                      onClick={() => setMenuActive(!menuActive)}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
