const dataRoadMap = [
  {
    id: 1,
    time: "Q4 2023",
    title: "Project Initiation & Preparation",
    desc: "Announcement of Money Tree Token Project: Official introduction and public announcement of the Money Tree Token project. Finalization of Market Research: Conduct thorough market research to better understand the dynamics of the crypto industry. Identification of Physical Silver Vendors: Establish partnerships with reputable vendors for the procurement of physical silver.",
    class: "",
  },
  {
    id: 2,
    time: "Q1 2024",
    title: "Planning & Design",
    desc: "Ecosystem Design: Develop the overall structure and design of the Money Tree Token ecosystem. Tokenomics Design Refinement: Fine-tune the economic model and tokenomics of Money Tree Token to ensure stability and sustainability. Market Entry Strategy Design: Plan and strategize the entry into the market with a comprehensive strategy.",
    class: "right",
  },

  {
    id: 3,
    time: "Q2 2024",
    title: "Development & Launch Preparation",
    desc: "Money Tree Token Ecosystem Development: Begin the development phase of the Money Tree Token ecosystem, incorporating the planned features and functionalities. Smart Contract Auditing: Conduct rigorous auditing of smart contracts to ensure security and reliability. Beta Launch: Launch a beta version of the Money Tree Token platform for testing and feedback. Money Tree Token Presale: Initiate the presale of Money Tree Tokens, offering early supporters the opportunity to acquire tokens. First DAO Vote: Implement the first decentralized autonomous organization (DAO) vote, involving the community in decision-making. First Payout for Physical Silver: Distribute the first payout of physical silver to participants after the sale of a predetermined number of tokens.",
    class: "",
  },

  {
    id: 4,
    time: "Q3 2024",
    title: "Public Sale & Market Expansion",
    desc: "Money Tree Token Public Sale: Open the public sale of Money Tree Tokens, allowing a broader audience to participate. Intense Marketing Initiatives: Implement robust marketing initiatives to promote Money Tree Token and attract a larger user base.",
    class: "right",
  },
  {
    id: 5,
    time: "Future Plans",
    title: "Ongoing Innovation & Expansion",
    desc: "Integration with a Private Blockchain: Explore the integration of Money Tree Token with a private blockchain to enhance speed, security, and affordability. This phase will be implemented two years after the launch of the Money Tree Token ecosystem.",
    class: "",
  },
];

export default dataRoadMap;
