import Airdrop from "./Airdrop";
import Home from "./Home";
import Presale from "./Presale";
import Stake from "./Stake";

const routes = [
  { path: "/", component: <Home /> },
  { path: "/stake", component: <Stake /> },
  { path: "/presale", component: <Presale /> },
  { path: "/airdrop", component: <Airdrop /> },
];

export default routes;
