const dataFaqs = [
  {
    id: 1,
    title: "What is Money Tree Token?",
    text: "Money Tree Token is an emerging cryptocurrency that distinguishes itself by being silver-backed, providing stability and real-world value. It addresses common issues in the crypto industry, such as extreme token depreciations, unguaranteed earning methods, and a lack of off-chain value.",
    show: "",
  },
  {
    id: 2,
    title: "How is Money Tree Token different from other cryptocurrencies?",
    text: "Money Tree Token stands out by being backed by silver, mitigating extreme token depreciations. It offers multiple guaranteed earning avenues, including staking and liquidity pools, and is designed with off-chain value, being accepted as a means of payment on the Money Tree Token jewelry store marketplace.",
    show: "",
  },
  {
    id: 3,
    title: "How does Money Tree Token work?",
    text: "Money Tree Token is mined with each transaction, and tokens are deposited in sellers' wallets. Successful transactions result in the mining of tokens as a percentage of the transaction value. The platform aims to enable stakeholders to earn at least $50 daily, with membership subscriptions providing additional benefits.",
    show: "",
  },
  {
    id: 4,
    title: "What are the membership benefits of Money Tree Token?",
    text: "Members enjoy privileges such as the ability to mint Money Tree Token, community voting rights, participation in governance, membership to the jewelry store marketplace, access to platform statistics, and the opportunity to buy silver at a spot price or slightly above.",
    show: "",
  },
  {
    id: 5,
    title: "What is the cost of membership for Money Tree Token?",
    text: "Investors are required to pay $50 for an annual membership. This fee grants them various benefits, including the ability to mine Money Tree Token, participate in community governance, and access the jewelry store marketplace.",
    show: "",
  },
  {
    id: 6,
    title: "How is silver involved in Money Tree Token?",
    text: "Money Tree Token is backed by silver, and the number of tokens in supply depends on the ounces of silver sold. Presale participants are direct owners of silver ounces and must subscribe to the membership program to have token mining power.",
    show: "",
  },
  {
    id: 7,
    title: "What is the transaction fee structure for Money Tree Token?",
    text: "For every transaction, a 10 percent fee is charged, with 75 percent of the fee distributed to the community, and the remaining 25 percent used to fund the day-to-day operations of the Money Tree Token ecosystem.",
    show: "",
  },
  {
    id: 8,
    title: "What are the future plans for Money Tree Token?",
    text: "Money Tree Token plans to evolve with the changing needs of crypto enthusiasts by implementing updates, such as converting into a coin and developing a private blockchain. These changes will be initiated based on the platform's user base.",
    show: "",
  },
];

export default dataFaqs;
