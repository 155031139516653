import React from "react";
import "../scss/airdrop.scss";
import { TabPanel, Tabs } from "react-tabs";
import { Accordion } from "react-bootstrap-accordion";
import dataFaqs from "../assets/fake-data/data-faq";
import Footer from "../components/footer/Footer";
import ReactPlayer from "react-player";

const Airdrop = () => {
  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <div
        className="airdrop"
        style={{
          overflow: "hidden",
        }}
      >
        <section
          id="hero"
          data-w-id="0d11d430-1e2f-a52c-524a-e242de232bcb"
          className="a-hero"
        >
          <div className="a-hero-wrapper">
            <div className="a-hero-heading">
              <div
                data-w-id="ef536a42-1f60-68e0-961b-c81d1e3aa215"
                style={{ opacity: 0 }}
                className="caps dark20"
              >
                $MT is available on Bybit
              </div>
              <h1
                data-w-id="49853630-c932-fc2d-8399-4a1b6601b077"
                className="h1 relative"
              >
                $MT Token airdrop
              </h1>
            </div>
            <div
              data-w-id="eaadf105-6cbf-6168-a34e-a9aa92a266b6"
              className="p-l"
            >
              The campaign is ended now. The tokens will be airdropped to your
              Money Tree platform.
            </div>
          </div>
          <a
            href="#"
            data-w-id="df0bd645-9297-b7e9-1f90-3d2045717bdd"
            target="_blank"
            className="action-btn"
          >
            <span>Go To Platform</span>
          </a>
          <img
            src="/logo.png"
            loading="lazy"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 209.98585510253906px, 27vw"
            alt=""
            className="a-hero-decor-1"
            style={{
              borderRadius: "50%",
            }}
          />
          <img
            src="https://assets-global.website-files.com/64daf30b4d883a6ecefe00db/6583d1f9f31288d455a85163_Rocks-bg.webp"
            loading="lazy"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 255px, 24vw"
            srcSet="https://assets-global.website-files.com/64daf30b4d883a6ecefe00db/6583d1f9f31288d455a85163_Rocks-bg-p-500.webp 500w, https://assets-global.website-files.com/64daf30b4d883a6ecefe00db/6583d1f9f31288d455a85163_Rocks-bg.webp 689w"
            alt=""
            className="a-hero-decor-6"
          />
          <img
            src="https://assets-global.website-files.com/64daf30b4d883a6ecefe00db/6583d1f9ffbdb96932f040f9_Rocks-bg2.webp"
            loading="lazy"
            alt=""
            className="a-hero-decor-7"
          />
        </section>
        {/* <div className="a-banner">
          <img
            src="https://assets-global.website-files.com/64daf30b4d883a6ecefe00db/65841e234582d1c272d48f54_Rock.webp"
            loading="lazy"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 300px, 39vw"
            srcSet="https://assets-global.website-files.com/64daf30b4d883a6ecefe00db/65841e234582d1c272d48f54_Rock-p-500.webp 500w, https://assets-global.website-files.com/64daf30b4d883a6ecefe00db/65841e234582d1c272d48f54_Rock-p-800.webp 800w, https://assets-global.website-files.com/64daf30b4d883a6ecefe00db/65841e234582d1c272d48f54_Rock-p-1080.webp 1080w, https://assets-global.website-files.com/64daf30b4d883a6ecefe00db/65841e234582d1c272d48f54_Rock.webp 1102w"
            alt=""
            className="a-hero-decor-2"
          />
          <div
            data-w-id="6497f43e-e5bb-de43-0ffa-f0cefcefc8d9"
            className="a-banner-wrapper"
          >
            <div className="a-banner-heading">
              <h2 className="h2 relative">$MT is available now on Bybit!</h2>
              <a
                href="#"
                data-w-id="aba33011-0819-dba8-e879-b56a3d0e37d2"
                target="_blank"
                className="action-btn"
              >
                <span>Go to Bybit</span>
              </a>
            </div>
          </div>
        </div> */}
        <section className="a-claimr">
          <div className="a-claimr-wrapper">
            <div className="a-claimr-heading">
              <h2
                data-w-id="d5e503cf-cc4f-fe42-6cb2-ecb60fea7bc1"
                className="h2"
              >
                The mission is ended
              </h2>
              <div className="a-claimr-text">
                <div className="p-l">
                  Thank you for participating $MT Token Airdrop event!
                  <br />
                  If you have participated in our airdrop campaign the $MT
                  tokens will be airdropped within 14 days to your Money Tree
                  platform.
                </div>
              </div>
              <div className="a-claimr-heading-btn">
                <a
                  href="#"
                  data-w-id="f25ed49e-d9e8-9516-9857-74e3296d9648"
                  target="_blank"
                  className="action-btn"
                >
                  <span>Go To Platform</span>
                </a>
              </div>
            </div>
            {/* <div
              data-w-id="9ef1da38-db56-46ae-a3e8-2dce55acdeec"
              className="a-claimr-chat"
            >
              <img
                src="https://assets-global.website-files.com/64daf30b4d883a6ecefe00db/6575518e76d35e9976e1ee54_cerber-ava.webp"
                loading="lazy"
                alt=""
                className="a-claimr-chat-ava"
              />
              <div className="a-claimr-chat-block">
                <div className="caps">Cerberus</div>
                <div className="a-claimr-chat-text">
                  <div className="p-m">
                    Attention, recruits. The mission has ended. Stand down and
                    keep your eyes peeled for upcoming missions. Dismissed!
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div id="mission" className="a-claimr-id">
            <div
              id="myClaimr"
              data-w-id="53f0c6a9-f99e-2647-b90a-9ba1b20acca5"
              className="a-claimr-iframe"
            ></div>
          </div>
        </section>
        {/* <div
          data-w-id="baa79ad7-a23a-1a9f-a299-7262917be7d9"
          className="a-video"
        >
          <img
            src="https://cointelegraph.com/magazine/wp-content/uploads/2024/01/magazine-Journeys-Dr-Who-Guy-Tony-Pearce-1024x576.jpg"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div> */}
        <div className="a-text">
          <div className="a-text-heading">
            <h2 data-w-id="d6eaa11b-6e73-e02c-1d01-057bd7465012" className="h2">
              Money Tree – Silver Based Blockchain Digital Assets
            </h2>
            <a
              href="#"
              data-w-id="b06ecf84-02ce-c55b-a765-11df2d548ba7"
              target="_blank"
              className="action-btn"
            >
              <span>Learn More</span>
            </a>
          </div>
        </div>
        <div className="shape"></div>
        <section id="drops" data-scroll="mid" className="a-drops">
          <div className="g-drops-wrapper">
            <div className="a-drops-left">
              <h2
                data-w-id="ef65cdbe-7008-514d-54f1-b84abee12c56"
                className="h2"
              >
                frequently asked questions
              </h2>
              <div
                data-w-id="2a0f3a53-e13d-5597-1ff8-8cfa89a78ff8"
                className="a-drops-left-block"
              >
                <div className="h4">
                  Need more help?
                  <br />
                  Join our{" "}
                  <a
                    href="#"
                    target="_blank"
                    className="purpur"
                    style={{
                      color: "#9d63fe",
                      fontWeight: "bold",
                    }}
                  >
                    Discord
                  </a>
                </div>
              </div>
            </div>
            <div
              data-w-id="ef65cdbe-7008-514d-54f1-b84abee12c6d"
              className="a-drops-right"
            >
              <Tabs>
                <TabPanel className="content-tab">
                  <div className="content-inner">
                    <div className="flat-accordion row">
                      <div className="col-12">
                        {dataFaqs.slice(0, 4).map((idx) => (
                          <Accordion
                            show={idx.show}
                            key={idx.id}
                            title={idx.title}
                            className="flat-toggle h6"
                          >
                            <p className="toggle-content">{idx.text} </p>
                          </Accordion>
                        ))}
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </section>
        <div
          data-w-id="2c7d7a8e-1736-e29d-255f-9d73aa2fe40f"
          className="a-footer"
        >
          <a href="#" className="a-footer-logo w-inline-block">
            <img
              src="/logo2.png"
              loading="lazy"
              data-w-id="2c7d7a8e-1736-e29d-255f-9d73aa2fe411"
              alt=""
              className="footer-logo-img-2"
            />
          </a>
          <div className="footer-soc-links-box">
            <div
              data-w-id="2c7d7a8e-1736-e29d-255f-9d73aa2fe423"
              className="caps-2"
            >
              stay in touch
            </div>
            <div className="footer-soc-links">
              <a
                id="youtube"
                data-w-id="2c7d7a8e-1736-e29d-255f-9d73aa2fe426"
                href="#"
                target="_blank"
                className="footer-soc-link"
              >
                youtube
              </a>
              <a
                id="instagram"
                data-w-id="2c7d7a8e-1736-e29d-255f-9d73aa2fe428"
                href="#"
                target="_blank"
                className="footer-soc-link"
              >
                instagram
              </a>
              <a
                id="discord"
                data-w-id="2c7d7a8e-1736-e29d-255f-9d73aa2fe42a"
                href="#"
                className="footer-soc-link"
              >
                Discord
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Airdrop;
