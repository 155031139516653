import React, { useState } from "react";

import img1 from "../../assets/images/layouts/about-01.png";
import img2 from "../../assets/images/layouts/about-02.png";
import img3 from "../../assets/images/layouts/about-03.png";
import img4 from "../../assets/images/layouts/about-04.png";
import img5 from "../../assets/images/layouts/about-05.png";
import Button from "../button/Button";
import ReactPlayer from "react-player";
import { useMediaQuery } from "@mui/material";

function About(props) {
  const sm = useMediaQuery("(max-width:600px)");
  const md = useMediaQuery("(max-width:1024px)");
  const [dataBlock] = useState({
    subheading: "About us",
    heading: "Silver Based Blockchain Digital Assets",
    desc1:
      "Money Tree Token provides breakthroughs and silver investment solutions through commodity tokenization with blockchain technology.",
    desc2:
      "Tackling concerns like extreme token depreciations, a lack of real-world value, and unpredictable earning methods, Money Tree Token sets itself apart by introducing innovative features that provide stability and security for modern investors.",
  });
  return (
    <section className="about" id="about">
      <div className="shape"></div>
      <div className="container">
        <div
          className="row rev"
          style={{
            flexDirection: md && "column",
            alignItems: "center",
          }}
        >
          <div className="col-xl-6 col-md-12">
            <div className="about__right">
              <div
                className="images"
              >
                <img
                  src="/assets/about.gif"
                  width="100%"
                  height="100%"
                  style={{
                    position: "relative",
                    zIndex: 2,
                    pointerEvents: "none",
                    filter: "grayscale(100%) brightness(1)",
                    width: "100%",
                    height: "100%",
                    maxWidth: sm ? 400 : 450,
                    maxHeight: sm ? 300 : 450,
                  }}
                />
                {!sm && <div className="about-gradient" />}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="block-text">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading">{dataBlock.heading}</h3>
              <p className="mb-17">{dataBlock.desc1}</p>
              <p className="mb-26">{dataBlock.desc2}</p>
              {/* <Button link="/about" title="More About Us" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
