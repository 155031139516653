import img1 from "../images/svg/icon-1.svg";
import img2 from "../images/svg/icon-2.svg";
import img3 from "../images/svg/icon-3.svg";
import img4 from "../images/svg/icon-4.svg";

const dataPortfolio = [
  {
    id: 1,
    img: img1,
    title: "Decentralized & Secure",
    desc: "Precious metals reserves backing the tokens are vaulted by industry-leading custodians across multiple locations, reducing the single point of failure.",
  },
  {
    id: 2,
    img: img3,
    title: "Easy To Use & Accessible",
    desc: "Money Tree tokens are easy to use, no advanced crypto expertise is needed, and anyone can access the tokens 24/7 via our network of distributors and mobile application in seconds.",
  },
  {
    id: 3,
    img: img4,
    title: "Affordable Prices",
    desc: "The decentralized nature of the blockchain technology, with multiple distributors and platforms enable price arbitrage, for you to access competitive pricing per gram (token).",
  },
];

export default dataPortfolio;
