import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputBase,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import Border from "./images/Border.png";
// import Binance from "./images/Binance.png";
// import Arrow from "./images/Arrow.png";
// import ClamBox from "./images/ClamBox.png";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/styles";
// import presalebg from "./images/presalebg.png";
import { toast } from "react-toastify";
import web3 from "../web3";
// import { useMetaMask } from "../utils/useMetamask";
import stakingContract from "../utils/stakingContract";
import presaleContract from "../utils/presaleContract";
// import TotalStakersRecord from "./TotalStakersRecord";
import { ConnectKitButton } from "connectkit";
// import YourStatistics from "./YourStatistics";
import { stakingAddress, tokenAddress } from "../utils/constants";
import stakingAbi from "../utils/stakingAbi.json";
import tokenAbi from "../utils/tokenAbi.json";
import {
  useAccount,
  useContractRead,
  useContractReads,
  useContractWrite,
} from "wagmi";
import moment from "moment/moment";
import { formatUnits } from "viem";
import tokenContract from "../utils/tokenContract";
// import { toast } from "react-toastify";
// import Staked from "./Staked";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background: "#044474",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    background: "linear-gradient(94.36deg, #074B9E 1.54%, #5BF8FF 92.86%)",
  },
}));

export default function Stake() {
  // const { address, isConnected } = useMetaMask();
  const [open, setOpen] = React.useState(false);
  const [balance, setBalance] = React.useState(0);
  const [rewards, setRewards] = React.useState(null);
  const [limit, setLimit] = useState({
    min: 1,
    max: 100,
  });
  const [tokenDecimals, settokenDecimals] = useState();
  const [stakeAmount, setStakeAmount] = useState("");
  const [unStakeAmount, setUnStakeAmount] = useState("");
  const [tokenValue, setTokenValue] = useState();
  const { address, isConnected } = useAccount();
  const [duration, setDuration] = useState([]);
  const [tokenValue1, setTokenValue1] = useState(0);
  const [tokenValue2, setTokenValue2] = useState("");
  const [durationValue, setDurationValue] = useState(0);
  const [lockedUntil, setLockedUntil] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [allowance, setAllowance] = useState(0);
  const [staked, setStaked] = useState([]);
  const [stakers, setStakers] = useState({
    totalStakers: null,
    totalStaked: null,
    bonus: null,
    realtimeReward: null,
  });

  const [indexValue, setIndexValue] = useState(-1);
  const [indexValue1, setIndexValue1] = useState(-1);
  const [stakeCount, setStakeCount] = useState(0);
  const [userData, setUserData] = useState({
    earn: null,
    claimedReward: null,
    totalStaked: null,
    totalUnStaked: null,
    currentStaked: null,
    walletBalance: null,
  });

  const stakingContract = {
    address: stakingAddress,
    abi: stakingAbi,
  };

  const { data: stakeConvert, isLoading: loading1 } = useContractReads({
    contracts: [
      {
        ...stakingContract,
        functionName: "Bonus",
        args: "0",
      },
      {
        ...stakingContract,
        functionName: "Bonus",
        args: "1",
      },
      {
        ...stakingContract,
        functionName: "Bonus",
        args: "2",
      },
      {
        ...stakingContract,
        functionName: "Bonus",
        args: "3",
      },
      {
        ...stakingContract,
        functionName: "Bonus",
        args: "4",
      },
      {
        ...stakingContract,
        functionName: "unstakePercent",
      },
    ],
    watch: true,
  });

  const { data: durationData, isLoading: loading2 } = useContractReads({
    contracts: [
      {
        ...stakingContract,
        functionName: "Duration",
        args: "0",
      },
      {
        ...stakingContract,
        functionName: "Duration",
        args: "1",
      },
      {
        ...stakingContract,
        functionName: "Duration",
        args: "2",
      },
      {
        ...stakingContract,
        functionName: "Duration",
        args: "3",
      },
      {
        ...stakingContract,
        functionName: "Duration",
        args: "4",
      },
    ],
    watch: true,
  });

  const { writeAsync: staking, isLoading: isLoading3 } = useContractWrite({
    address: stakingAddress,
    abi: stakingAbi,
    functionName: "stake",
    args: [web3.utils.toWei(stakeAmount, "ether"), durationValue],
    overrides: {
      gasLimit: "2200000",
    },
    onSuccess(data) {
      toast.success("Transaction Successful");
    },
    onError(error) {
      if (error?.data?.message) {
        toast.error(error?.data?.message);
      } else if (error?.reason) {
        toast.error(error?.reason);
      } else {
        toast.error(error?.message);
      }
    },
  });
  const { writeAsync: approve, isLoading: isLoading4 } = useContractWrite({
    address: tokenAddress,
    abi: tokenAbi,
    functionName: "approve",
    args: [stakingAddress, web3.utils.toWei("10000000000000000000", "ether")],
    overrides: {
      gasLimit: "2200000",
    },
    onSuccess(data) {
      toast.success("Transaction Successful");
    },
    onError(error) {
      if (error?.data?.message) {
        toast.error(error?.data?.message);
      } else if (error?.reason) {
        toast.error(error?.reason);
      } else {
        toast.error(error?.message);
      }
    },
  });
  const { data: stakingRead, isLoading: loading5 } = useContractReads({
    contracts: [
      { ...stakingContract, functionName: "totalStakedToken" },
      { ...stakingContract, functionName: "totalStakers" },
      { ...stakingContract, functionName: "bonus", args: [2] },
      { ...stakingContract, functionName: "realtimeReward", args: [address] },
    ],
    watch: true,
  });

  const { writeAsync: withdraw, isLoading: isLoading1 } = useContractWrite({
    address: stakingAddress,
    abi: stakingAbi,
    functionName: "withdraw",
    args: [parseInt(indexValue1)],
    overrides: {
      gasLimit: "2200000",
    },
    onSuccess(data) {
      toast.success("Transaction Successful");
    },
    onError(error) {
      if (error?.data?.message) {
        toast.error(error?.data?.message);
      } else if (error?.reason) {
        toast.error(error?.reason);
      } else {
        toast.error(error?.message);
      }
    },
  });

  const { writeAsync: unstake, isLoading: isLoading2 } = useContractWrite({
    address: stakingAddress,
    abi: stakingAbi,
    functionName: "unStake",
    args: [parseInt(indexValue)],
    overrides: {
      gasLimit: "2200000",
    },
    onSuccess(data) {
      toast.success("Transaction Successful");
    },
    onError(error) {
      if (error?.data?.message) {
        toast.error(error?.data?.message);
      } else if (error?.reason) {
        toast.error(error?.reason);
      } else {
        toast.error(error?.message);
      }
    },
  });

  useEffect(() => {
    if (stakingRead) {
      console.log("stakingRead", stakingRead);
      let totalStaked = +formatUnits(stakingRead?.[0]?.result);
      let totalStakers = Number(stakingRead?.[1]?.result);
      let realtimeReward = formatUnits(Number(stakingRead?.[3]?.result));
      setStakers({
        totalStakers: totalStakers,
        totalStaked: totalStaked * 100000,
        realtimeReward: realtimeReward,
      });
    }
  }, [stakingRead, loading5]);

  const stakehandler = async () => {
    if (!stakeAmount) {
      toast.error("Enter Token.");
    } else if (!(allowance >= stakeAmount)) {
      await staking({});
    } else {
      await approve({});
    }
  };

  useEffect(() => {
    if (durationValue >= 0 && tokenValue1) {
      let tokenval1 = 0;
      tokenval1 =
        +tokenValue1 +
        (stakeConvert[durationValue] / stakeConvert[5]) * +tokenValue1;
      tokenval1 = tokenval1?.toFixed(2);
      setTokenValue2(tokenval1);
    } else {
      setTokenValue2("");
    }
    let value = Number(duration[durationValue]?.result) / 86400;
    let dur = moment().add(value, "day").format("dddd, MMMM Do YYYY");
    setLockedUntil(dur);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenValue1, duration, durationValue]);

  useEffect(() => {
    console.log(durationData);
    if (durationData) {
      setDuration(durationData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [durationData]);

  useEffect(() => {
    if (loading1 || loading2 || isLoading3 || isLoading4) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading1, loading2, isLoading3, isLoading4]);

  const onChangeHandler = (value) => {
    if (value) {
      setTokenValue1(value);
    } else {
      setTokenValue1(0);
    }
  };

  useEffect(() => {
    if (address) {
      (async () => {
        const bal = await tokenContract.methods
          .balanceOf(address)
          .call({ from: address });
        setBalance(web3.utils.fromWei(bal, "ether"));
      })();
    }
  }, [address, open]);

  useEffect(() => {
    if (isLoading1 || isLoading2) {
      setOpen(true);
    } else {
      setOpen(false);
      setIndexValue1(-1);
      setIndexValue(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading1, isLoading2]);

  const unStakeHandler = async (value) => {
    setIndexValue(value);
  };

  useEffect(() => {
    if (indexValue >= 0) unstake({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexValue]);

  const withdrawHandler = async (value) => {
    setIndexValue1(value);
  };

  useEffect(() => {
    if (indexValue1 >= 0) withdraw({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexValue1]);

  const tokenContract2 = {
    address: tokenAddress,
    abi: tokenAbi,
  };

  const { data, isLoading: loading4 } = useContractReads({
    contracts: [
      {
        ...stakingContract,
        functionName: "Stakers",
        args: [address],
      },
      {
        ...stakingContract,
        functionName: "realtimeReward",
        args: [address],
      },
      {
        ...tokenContract2,
        functionName: "allowance",
        args: [address, stakingAddress],
      },
    ],
    enabled: isConnected,
    watch: true,
  });

  const multiRead = (value, name) => {
    let data = [];
    for (let index = 0; index < value; index++) {
      data.push({
        ...stakingContract,
        functionName: name,
        args: [address, index],
      });
    }
    return {
      data: data,
    };
  };
  const { data: stakersRecordContract } = multiRead(
    stakeCount,
    "stakersRecord"
  );

  const { data: stakersData, isLoading: loading3 } = useContractReads({
    contracts: stakersRecordContract,
    enabled: isConnected,
    watch: true,
  });

  useEffect(() => {
    if (data && stakersData && isConnected) {
      console.log("stakersData", stakersData);
      let individualData = data[0];
      let earn = data[2];
      let currentStaked = 0;

      let stakersDataFilter = stakersData.filter(function (el) {
        return el[6] === false && el[7] === false;
      });

      stakersDataFilter?.forEach((element) => {
        let amount = +formatUnits(element[3]);
        currentStaked = currentStaked + amount;
      });

      let claimedReward = formatUnits(Number(individualData?.[3]));
      let totalStaked = formatUnits(Number(individualData?.[0]));
      let totalUnStaked = formatUnits(Number(individualData?.[2]));
      let earnedToken = formatUnits(Number(earn));

      setUserData({
        earn: Number(earnedToken),
        claimedReward: Number(claimedReward),
        totalStaked: Number(totalStaked),
        totalUnStaked: Number(totalUnStaked),
        currentStaked: Number(currentStaked),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, stakersData]);
  return (
    <Box
      className="stake"
      sx={{
        // background: `url(${presalebg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: { sm: "100% 100%", xs: "contain" },
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box pb={{ xs: 5, sm: 8, md: 12 }} pt={5}>
        <Container maxwidth="lg">
          <br />
          <br />
          <h3 className="heading text-center">STAKING DASHBOARD</h3>
          <Box
            py={10}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "15px",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "8px",
                padding: "15px 25px",
                width: 250,
              }}
            >
              <h6 style={{ color: "#5c27fe" }}>Earn Upto</h6>
              <h4>30% APR</h4>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "15px",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "8px",
                padding: "15px 25px",
                width: 250,
              }}
            >
              <h6 style={{ color: "#5c27fe" }}>Total Value Staked</h6>
              <h4>${(stakers?.totalStaked * 0.5).toFixed(3)}</h4>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "15px",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "8px",
                padding: "15px 25px",
                width: 250,
              }}
            >
              <h6 style={{ color: "#5c27fe" }}>Total Tokens Staked</h6>
              <h4>{stakers?.totalStaked?.toFixed(3)}</h4>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "15px",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "8px",
                padding: "15px 25px",
                width: 250,
              }}
            >
              <h6 style={{ color: "#5c27fe" }}>Unique Stakers</h6>
              <h4>{stakers?.totalStakers}</h4>
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "100% 100%",
                  width: "100%",
                  padding: "25px",
                  textAlign: "center",
                }}
              >
                <Box my={2} p={3} className="action-btn">
                  <Stack
                    direction="row"
                    p={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Box fontSize="28px" textAlign="center">
                        STAKE MT TOKEN
                      </Box>
                      <Box
                        style={{
                          border: "1px solid rgba(255,255,255,0.2)",
                          borderRadius: "8px",
                          marginTop: "30px",
                          padding: "5px 10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <InputBase
                          min="0"
                          type="number"
                          placeholder="0.00"
                          value={stakeAmount}
                          onChange={(e) => setStakeAmount(e.target.value)}
                          sx={{
                            color: "#fff",
                            fontSize: { xs: "20px", md: "35px" },
                            "& ::placeholder": {
                              fontSize: { xs: "20px", md: "35px" },
                            },
                          }}
                        />
                        <button
                          onClick={() => setStakeAmount(balance)}
                          className="action-btn"
                        >
                          <span>MAX</span>
                        </button>
                      </Box>

                      <Box mt={2} style={{}}>
                        {duration?.map((value, index) => (
                          <button
                            className="action-btn"
                            key={index}
                            onClick={() => setDurationValue(index)}
                          >
                            <span>{Number(value.result) / 86400} Days</span>
                          </button>
                        ))}
                      </Box>
                      <Box variant="p" textAlign="center" my={1}>
                        Locked until {lockedUntil}
                      </Box>
                      <button
                        onClick={() => stakehandler()}
                        style={{
                          marginTop: "20px",
                        }}
                        className="action-btn"
                      >
                        <span>
                          {allowance >= stakeAmount ? "Stake" : "Approve"}
                        </span>
                      </button>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "100% 100%",
                  width: "100%",
                  padding: "25px",
                  textAlign: "center",
                }}
              >
                <Box my={2} p={3} className="action-btn">
                  <Stack
                    direction="row"
                    p={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Box fontSize="28px" textAlign="center">
                        Claim Reward OR UnStake Tokens
                      </Box>
                      <Box mt={2} style={{}}>
                        {duration?.map((value, index) => (
                          <button
                            className="action-btn"
                            key={index}
                            onClick={() => setDurationValue(index)}
                          >
                            <span
                              style={{
                                backgroundColor:
                                  durationValue !== index ? "#e5e5e5" : "",
                              }}
                            >
                              {Number(value.result) / 86400} Days
                            </span>
                          </button>
                        ))}
                      </Box>
                      <button
                        onClick={() => withdrawHandler(durationValue)}
                        style={{
                          marginTop: "20px",
                        }}
                        className="action-btn"
                      >
                        <span>Withdraw</span>
                      </button>
                      <button
                        onClick={() => unStakeHandler(durationValue)}
                        style={{
                          marginTop: "20px",
                        }}
                        className="action-btn"
                      >
                        <span>UnStake</span>
                      </button>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <br />
          <br />
          <h3 className="heading text-center">YOUR STATISTICS</h3>
          <Box
            py={10}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "15px",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "8px",
                padding: "15px 25px",
                width: 250,
              }}
            >
              <h6 style={{ color: "#5c27fe" }}>Realtime Reward</h6>
              <h4>
                {stakers?.realtimeReward
                  ? Number(stakers?.realtimeReward).toFixed(5)
                  : 0.0}
              </h4>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "15px",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "8px",
                padding: "15px 25px",
                width: 250,
              }}
            >
              <h6 style={{ color: "#5c27fe" }}>Reward Earn</h6>
              <h4>{userData?.earn ? Number(userData.earn).toFixed(3) : 0.0}</h4>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "15px",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "8px",
                padding: "15px 25px",
                width: 250,
              }}
            >
              <h6 style={{ color: "#5c27fe" }}>Reward Claimed</h6>
              <h4>{userData?.claimedReward ? userData.claimedReward : 0.0}</h4>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "15px",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "8px",
                padding: "15px 25px",
                width: 250,
              }}
            >
              <h6 style={{ color: "#5c27fe" }}>Current Staked</h6>
              <h4>{stakers?.totalStaked ? stakers?.totalStaked : 0.0}</h4>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "15px",
                border: "1px solid rgba(255,255,255,0.2)",
                borderRadius: "8px",
                padding: "15px 25px",
                width: 250,
              }}
            >
              <h6 style={{ color: "#5c27fe" }}>Total UnStaked</h6>
              <h4>{balance ? Number(balance).toFixed(3) : 0.0}</h4>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
