import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputBase,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/styles";
import { toast } from "react-toastify";
import web3 from "../web3";
import presaleContract from "../utils/presaleContract";
import { useAccount } from "wagmi";

export default function Presale() {
  const { address, isConnected } = useAccount();
  const [open, setOpen] = React.useState(false);
  const [balance, setBalance] = React.useState(0);
  // const { data: signer } = useWalletClient();
  const [limit, setLimit] = useState({
    min: 1,
    max: 100,
  });
  const [tokenDecimals, settokenDecimals] = useState();
  const [tokensAmount, settokensAmount] = useState("");
  const [tokenValue, setTokenValue] = useState();

  useEffect(() => {
    if (address) {
      (async () => {
        const bal = await presaleContract.methods
          .balanceOf(address)
          .call({ from: address });
        setBalance(web3.utils.fromWei(bal, "ether"));
      })();
    }
  }, [address]);

  const convertToken = async () => {
    try {
      setOpen(true);
      let amount = web3.utils.toWei(tokensAmount, "ether");
      console.log("amount", amount);
      let token = await presaleContract.methods.ethToToken(amount).call();
      token = web3.utils.fromWei(token, "ether").toString();
      console.log("token", token);
      setTokenValue(token);
      setOpen(false);
    } catch (error) {
      toast(error);
    }
  };

  const buyHandler = async () => {
    // setOpen(true);
    if (!isConnected) {
      toast.error("Please Connect Wallet");
    } else if (tokensAmount === "") {
      toast.error("Please Enter Amount");
    } else if (tokensAmount === "0") {
      toast.error("Please Enter Valid Amount");
    } else {
      try {
        const tx1 = await presaleContract.methods.buy().send({
          from: address,
          value: web3.utils.toWei(tokensAmount, "ether"),
          gas: 2200000,
          gasPrice: 50000000,
        });

        console.log(tx1);
        await Promise.resolve(tx1);
        setOpen(false);
      } catch (error) {
        if (error?.data?.message) {
          toast.error(error?.data?.message);
        } else if (error?.data?.reason) {
          toast.error(error?.data?.reason);
        } else {
          toast.error(error?.message);
        }
        // setOpen(false);
      }
    }
  };

  useEffect(() => {
    async function value() {
      let dec = await presaleContract.methods.decimals().call();
      console.log("dec", web3.utils.fromWei(dec, "wei"));
      settokenDecimals(dec.toString());
      if (isConnected && tokensAmount) return convertToken();
    }
    value();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokensAmount, isConnected]);
  return (
    <Box
      className="presale"
      sx={{
        // background: `url(${presalebg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: { sm: "100% 100%", xs: "contain" },
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box pb={{ xs: 5, sm: 8, md: 12 }} pt={5}>
        <Container maxwidth="lg">
          <Box
            variant="h3"
            my={{ xs: 5, md: 10 }}
            textAlign="center"
            sx={{
              fontFamily: "'Zen Dots'",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: { sm: "36px", xs: "28px" },
            }}
          >
            MONEY TREE TOKEN PRESALE
            <br />
            Available In Wallet: ${Number(balance).toFixed(4)} MT
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} mx={"auto"}>
              <Box
                sx={{
                  //   background: `url(${Border})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "100% 100%",
                  width: "100%",
                  padding: "25px",
                  textAlign: "center",
                  flexDirection: "column",
                  gap: "0px",
                  justifyContent: "flex-start",
                }}
                className="action-btn"
              >
                <Box my={2} p={3}>
                  <Stack
                    direction="row"
                    p={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Box fontSize="21px" textAlign="left">
                        Sepolia ETH
                      </Box>
                      <InputBase
                        min="0"
                        type="number"
                        placeholder="0.00"
                        onChange={(e) => settokensAmount(e.target.value)}
                        sx={{
                          color: "#fff",
                          border: "1px solid rgba(255,255,255,0.2)",
                          borderRadius: "8px",
                          marginTop: "10px",
                          padding: "5px 10px",
                          fontSize: { xs: "20px", md: "35px" },
                          "& ::placeholder": {
                            fontSize: { xs: "20px", md: "35px" },
                          },
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    direction="row"
                    p={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Box fontSize="21px" textAlign="left">
                        MONEY TREE TOKEN
                      </Box>
                      <InputBase
                        readOnly
                        value={tokenValue || "0"}
                        sx={{
                          color: "#fff",
                          border: "1px solid rgba(255,255,255,0.2)",
                          borderRadius: "8px",
                          marginTop: "10px",
                          padding: "5px 10px",
                          fontSize: { xs: "20px", md: "35px" },
                          "& ::placeholder": {
                            fontSize: { xs: "20px", md: "35px" },
                          },
                        }}
                      />
                    </Box>
                  </Stack>
                </Box>
                <button
                  onClick={buyHandler}
                  style={{
                    marginTop: "-20px",
                    marginBottom: "20px",
                  }}
                  className="action-btn"
                >
                  <span>Buy Now</span>
                </button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
