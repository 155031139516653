import img1 from "../images/icon/icon1.png";
import img2 from "../images/icon/icon2.png";
import img3 from "../images/icon/icon3.png";
import img4 from "../images/icon/icon4.png";

const dataBox = [
  {
    id: 1,
    img: img1,
    title: "Physical Exchange",
    desc: "Guaranteed by 1 gram of silver, exchangeable for silver bars.",
  },
  {
    id: 2,
    img: img2,
    title: "Protect Your Wealth",
    desc: "Keep your hard-earned savings safe with physical metals.",
  },
  {
    id: 3,
    img: img3,
    title: "Secure Storage",
    desc: "Your silver are safely stored in our world-class vaults.",
  },
  {
    id: 4,
    img: img4,
    title: "Spend Instantly",
    desc: "Use your silver& crypto as money, anywhere in the world.",
  },
];

export default dataBox;
